require("cookieconsent/build/cookieconsent.min.js");

window.addEventListener("load", function () {
  window.cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#000000",
        "text": "#ffffff"
      },
      "button": {
        "background": "#007c02",
        "text": "#ffffff"
      }
    },
    "theme": "classic",
    "position": "bottom",
    "content": {
      "message": "Wir verwenden Cookies, um unsere Website für Sie möglichst benutzerfreundlich zu gestalten. Wenn Sie fortfahren, nehmen wir an, dass Sie mit der Verwendung von Cookies einverstanden sind. Für Details klicken Sie auf\n",
      "dismiss": "OK",
      "link": "\"Weitere Informationen\"",
      "href": "/datenschutz",
      "target": ""
    }
  })
});
